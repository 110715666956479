import React, { Component } from 'react';
import Departure from './Departure.js';

class DepartureList extends Component {

    render() {
        return (
            <div className="departure-list-wrap">
                <div className="departure-list">
                    {this.props.departures.filter(item => this.props.filtered.length == 0 || this.props.filtered.includes(item.TransportMode)).map((item) => (
                        <Departure transport={ item } key={ item.JourneyNumber } />
                    ))}
                </div>
            </div>
        );
    }
}

export default DepartureList;
