import React, { Component } from 'react';

import Header from './Header.js';
import DepartureList from './DepartureList.js';
import Favourites from './Favourites.js';

import axios from 'axios';
import moment from 'moment';

import './App.css';

class App extends Component {
    constructor(props) {
        super(props);
        this.setSite = this.setSite.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
        moment.relativeTimeThreshold('s', 180);
        moment.relativeTimeThreshold('ss', 1);
        moment.updateLocale('en', {
            relativeTime : {
                future: "om %s",
                past:   "%s sedan",
                s  : '1s',
                ss : '%ds',
                m:  "1m",
                mm: "%dm",
                h:  "1h",
                hh: "%dh",
                d:  "en dag",
                dd: "%d dagar",
                M:  "en månad",
                MM: "%d månader",
                y:  "ett år",
                yy: "%d år"
            }
        });
    }

    state = {
        departures: [],
        query: null,
        siteId: null,
        siteName: null,
        updateInterval: null,
        filtered: []
    }

    mergeState(obj, callback=null) {
        const newState = Object.assign({}, this.state, obj);
        this.setState(newState, callback);
    }

    componentDidMount() {
        let interval = setInterval(() => this.updateDepartures(), 15000);
        this.mergeState({updateInterval: interval});
        document.addEventListener('visibilitychange', this.handleVisibilityChange, false);
    }

    formatTransportList(transportList) {
        return transportList.map((t) => {
            t.TimeTabledDateTime = moment(t.TimeTabledDateTime);
            t.ExpectedDateTime = moment(t.ExpectedDateTime);
            return t;
        });
    }

    handleVisibilityChange() {
        let vis = document.visibilityState;
        if (vis === "hidden") {
            if (this.state.updateInterval !== null) {
                clearInterval(this.state.updateInterval);
                this.mergeState({updateInterval: null});
            }
        }
        else {
            if (this.state.updateInterval !== null) {
                clearInterval(this.state.updateInterval);
                this.mergeState({updateInterval: null});
            }
            this.updateDepartures();
            let interval = setInterval(() => this.updateDepartures(), 15000);
            this.mergeState({updateInterval: interval});
        }
    }

    setSite(siteId, siteName) {
        let siteUpdate = {
            siteId: siteId,
            siteName: siteName
        }

        this.mergeState(siteUpdate, this.updateDepartures);
    }

    setFilter(filterList) {
        let filterUpdate = {
            filtered: filterList
        }
        this.mergeState(filterUpdate, this.updateDepartures);
    }

    updateDepartures() {
        if (this.state.siteId === null) return;
        axios
            .get(process.env.REACT_APP_API_URL + '/deps?site_id=' + this.state.siteId)
            .then(response => {
                let departures_raw = response.data;
                if (departures_raw === null) {
                    console.log(response.data);
                    return; //TODO: Warn somehow. Don't know why this happens.
                }
                let departures = departures_raw.Buses.concat(departures_raw.Metros, departures_raw.Trams, departures_raw.Trains, departures_raw.Ships);
                departures = this.formatTransportList(departures);
                departures.sort((t1, t2) => { return t1.ExpectedDateTime - t2.ExpectedDateTime });
                this.mergeState({departures: departures});
            });
    }
    render() {
        let currentSite = {
            siteName: this.state.siteName,
            siteId: this.state.siteId
        };
        return (
            <div className="App">
                <Header setSiteFunc={ this.setSite } setFilterFunc={ this.setFilter } />
                <div className="content">
                    <Favourites setSite={ this.setSite } currentSite={ currentSite } />
                    <DepartureList departures={ this.state.departures } filtered={ this.state.filtered } />
                </div>
            </div>
        );
    }
}

export default App;
