import React, { Component } from 'react';

import MI from './MI.js';

import './Favourites.css';

class Favourites extends Component {
    constructor(props) {
        super(props);

        this.state = {
            favouriteList: []
        }
    }

    mergeState(obj, callback=null) {
        const newState = Object.assign({}, this.state, obj);
        this.setState(newState, callback);
    }

    componentDidMount() {
        this.loadStorage();
    }

    loadStorage() {
        let raw = localStorage.getItem('favourites');
        if (raw === null)
            return;
        this.mergeState({favouriteList: JSON.parse(raw)});
    }

    saveStorage() {
        localStorage.setItem('favourites', JSON.stringify(this.state.favouriteList));
    }

    setSite(siteId, event) {
        let site = this.state.favouriteList.filter( i => i.siteId === siteId )[0];
        this.props.setSite(site.siteId, site.siteName);
    }
    
    removeFavourite(siteId, event) {
        let filtered = this.state.favouriteList.filter( item => item.siteId !== siteId );
        this.mergeState({favouriteList: filtered}, this.saveStorage);
    }

    siteIsFav(siteId) {
        return (this.state.favouriteList.filter( item => item.siteId === siteId ).length > 0);
    }

    toggleFavourite(siteId, siteName) {
        if (this.siteIsFav(siteId)) {
            this.removeFavourite(siteId);
        } else {
            let favs = this.state.favouriteList;
            favs.push({
                siteId: siteId,
                siteName: siteName
            });

            this.mergeState({favouriteList: favs}, this.saveStorage);
        }
    }

    render() {
        let content;
        let curr = this.props.currentSite;
        if (curr.siteId === null) {
            content = (
                <div className='favourite-list'>
                    { this.state.favouriteList.map( (item) => (
                        <div className="favourite">
                            <a onClick={ (e) => this.removeFavourite(item.siteId, e) }>
                                <MI icon="star" style="fav-star" />
                            </a>
                            <a className="asdd" onClick={ (e) => this.setSite(item.siteId, e) }>
                                { item.siteName }
                            </a>
                        </div>
                    ))}
                </div>
            );
        } else {
            let icon;
            if (this.state.favouriteList.filter( i => i.siteId === curr.siteId ).length > 0) {
                icon = 'star';
            } else {
                icon = 'star_border';
            }
            content = (
                <h2><a onClick={ (e) => this.toggleFavourite(curr.siteId, curr.siteName) }><MI style="fav-star" icon={ icon } />{ curr.siteName }</a></h2>
            );
        }
        return (
            <>{ content }</>
        )
    }
}

export default Favourites;
