import React, { Component } from 'react';
import './SearchBar.css'
import axios from 'axios';

class SearchBar extends Component {
    state = {
        searchString: "",
        searchTimer: null
    };

    constructor(props) {
        super(props);

        this.setSearchString = this.setSearchString.bind(this);
        this.hideKeyboard = this.hideKeyboard.bind(this);
    }

    mergeState(obj, callback=null) {
        const newState = Object.assign({}, this.state, obj);
        this.setState(newState, callback);
    }

    setSearchString(e) {
        let searchString = e.target.value.trim();
        this.mergeState({
            searchString: searchString
        });

        if (this.state.searchTimer !== null) {
            clearTimeout(this.state.searchTimer);
        }

        if (searchString.length > 2) {
            let timer = setTimeout( () => {
                this.setSiteId(searchString);
            }, 1000);
            this.mergeState({
                searchTimer: timer,
                searchString: searchString
            });
        }
    }

    setSiteId(query) {
        axios
            .get(process.env.REACT_APP_API_URL + '/typeahead?q=' + query)
            .then(response => {
                if (response.data.length === 0)
                    return; // TODO: Warn? no results

                let site = response.data[0];
                console.log(site);
                this.props.setSiteFunc(site.SiteId, site.Name);
            });

    }

    hideKeyboard(s) {
        var activeElement = document.activeElement;
        console.log(activeElement);
        activeElement.setAttribute('readonly', 'readonly');
        setTimeout(() => {
            activeElement.blur();
            activeElement.removeAttribute('readonly');
        }, 100);
        if (this.state.searchTimer !== null) {
            clearTimeout(this.state.searchTimer);
        }

        this.setSiteId(this.state.searchString);
    }

    render() {
        return (
            <div className="SearchBar">
                <form action="#" onSubmit={this.hideKeyboard} >
                    <input type="text" onChange={ this.setSearchString } value={ this.state.searchString } />
                </form>
            </div>
        )
    }
}

export default SearchBar;
