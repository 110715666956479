import React, { Component } from 'react';
import SearchBar from './SearchBar.js';
import FilterBar from './FilterBar.js';

import './Header.css';

class Header extends Component {
    render() {
        return (
            <div className='header'>
                <SearchBar setSiteFunc={this.props.setSiteFunc} />
                <FilterBar setFilterFunc={this.props.setFilterFunc} />
            </div>
        );
    }
}

export default Header;
