import React, { Component } from 'react';

class MI extends Component {
    render() {
        return (
            <span className={ `material-icons ${ this.props.style }` }>{ this.props.icon }</span>
        )
    }
}

export default MI;
