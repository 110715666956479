import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import MI from './MI.js';
import { TRANSPORT_ICONS } from './Constants.js';
import moment from 'moment';

import './Departure.css';

import 'material-icons/iconfont/material-icons.css';

class Departure extends Component {
    state = {
        currentTime: moment()
    }

    mergeState(obj, callback=null) {
        const newState = Object.assign({}, this.state, obj);
        this.setState(newState, callback);
    }

    update = () => {
        this.mergeState({
            currentTime: moment()
        });
    };

    componentDidMount() {
        this.refreshTimer = setInterval(this.update, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.refreshTimer);
    }

    render() {
        const b = this.props.transport;
        let subheader = b.TimeTabledDateTime.format('HH:mm:ss')
        if (! b.TimeTabledDateTime.isSame(b.ExpectedDateTime)) {
            subheader = (<><i className="delayed">{subheader}</i><br />Ny tid: {b.ExpectedDateTime.format('HH:mm:ss')}</>);
        }
        let style = 'default'
        if (b.TransportMode === "METRO") {
            switch(b.GroupOfLine) {
                case "tunnelbanans blå linje":
                    style = 'blue';
                    break;
                case "tunnelbanans röda linje":
                    style = 'red';
                    break;
                case "tunnelbanans gröna linje":
                    style = 'green';
                    break
                default:
                    style = 'default'
                    break
            }
        }
        let icon = <MI icon={TRANSPORT_ICONS[b.TransportMode]} style={ `transport-mode ${ style }` } />
        let title = (<>{ icon } {b.LineNumber} mot {b.Destination}<br /> {b.ExpectedDateTime.fromNow()}</>);
        return (
            <Card className="departure-card">
                <CardHeader
                    title={title}
                    subheader={subheader}
                />
            </Card>
        )
    }
}

export default Departure;
