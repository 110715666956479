import React, { Component } from 'react';
import MI from './MI.js';
import { TRANSPORT_ICONS } from './Constants.js';
import './FilterBar.css'

class FilterBar extends Component {
    state = {
        filtered: []
    }

    mergeState(obj, callback=null) {
        const newState = Object.assign({}, this.state, obj);
        this.setState(newState, callback);
    }

    toggleTransport(transportType) {
        let filterList  = this.state.filtered;
        if (filterList.indexOf(transportType) === -1) {
            filterList.push(transportType);
        } else {
            filterList.splice(filterList.indexOf(transportType), 1);
        }

        this.mergeState({filtered: filterList});
        this.props.setFilterFunc(this.state.filtered);
    }

    render() {
        return (
            <div className="FilterBar">
            {Object.entries(TRANSPORT_ICONS).map((k, v) => (
                <button key={k[0]} onClick={() => this.toggleTransport(k[0])} className="filter-button"><MI icon={k[1]} style={this.state.filtered.indexOf(k[0]) === -1 ? 'visible' : 'hidden'} /></button>
            ))}
            </div>
        )
    }
}

export default FilterBar;

